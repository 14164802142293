// src/theme.js
/*import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2', // Azul estándar de Material UI
    },
    secondary: {
      main: '#dc004e', // Color secundario para contrastes
    },
    background: {
      default: '#f4f6f8', // Fondo general para mejorar legibilidad
    },
  },
  typography: {
    fontFamily: 'Roboto, Arial, sans-serif',
    h1: { fontSize: '2.5rem', fontWeight: 600 },
    h2: { fontSize: '2rem', fontWeight: 500 },
    // Añade más configuraciones de tipografía según tus necesidades
  },
  components: {
    // Personaliza componentes específicos de MUI
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
        },
      },
    },
  },
});

export default theme;
*/

// src/theme.js
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2', // Color principal para botones y enlaces
    },
    secondary: {
      main: '#dc004e', // Color secundario para otros elementos
    },
    dark: {
      main: '#292929', // Fondo oscuro personalizado para el footer
    },
    background: {
      default: '#f4f6f8', // Fondo por defecto
    },
  },
  typography: {
    fontFamily: 'Roboto, Arial, sans-serif',
    h1: { fontSize: '2.5rem', fontWeight: 600 },
    // Agrega más estilos de tipografía aquí si lo necesitas
  },
});

export default theme;
